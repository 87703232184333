import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [];

export const dictionary = {
		"/(web)": [45,[11]],
		"/3d": [57],
		"/(web)/(chatbots)/about": [47,[11,13]],
		"/(admin)/admin/dashboard": [15,[2]],
		"/(app)/app": [16,[3,4]],
		"/(app)/app/date": [18,[3,4,5]],
		"/(app)/app/date/[dateCompanionId]": [19,[3,4,5]],
		"/(app)/app/gallery": [20,[3,4,6]],
		"/(app)/app/gallery/[imageId]": [21,[3,4,6]],
		"/(app)/app/onboarding-deprecated": [23,[8]],
		"/(app)/app/onboarding-deprecated/interests": [24,[8]],
		"/(app)/app/onboarding": [22,[7]],
		"/(app)/app/settings/(sub-settings)/account": [25,[3,4,9]],
		"/(app)/app/settings/(sub-settings)/companions": [26,[3,4,9]],
		"/(app)/app/settings/(sub-settings)/companions/[companionId]": [27,[3,4,9,10]],
		"/(app)/app/settings/(sub-settings)/companions/[companionId]/appearance": [28,[3,4,9,10]],
		"/(app)/app/settings/(sub-settings)/companions/[companionId]/personality": [29,[3,4,9,10]],
		"/(app)/app/settings/(sub-settings)/companions/[companionId]/voice": [30,[3,4,9,10]],
		"/(app)/app/settings/(sub-settings)/profile": [31,[3,4,9]],
		"/(app)/app/settings/(sub-settings)/subscription": [32,[3,4,9]],
		"/(app)/app/success": [33,[3]],
		"/(app)/app/[catch_redirect]": [17,[3,4]],
		"/(web)/articles": [53,[11]],
		"/(web)/articles/[slug]": [54,[11]],
		"/(app)/auth": [34,[3]],
		"/(app)/auth/forgot-password": [36,[3]],
		"/(app)/auth/forgot-password/check-mails": [37,[3]],
		"/(app)/auth/forgot-password/set-new": [38,[3]],
		"/(app)/auth/login": [39,[3]],
		"/(app)/auth/logout": [40,[3]],
		"/(app)/auth/redirect-to-app": [41,[3]],
		"/(app)/auth/register": [42,[3]],
		"/(app)/auth/register/verify-email": [43,[3]],
		"/(app)/auth/[catch_redirect]": [35,[3]],
		"/(web)/blog/[slug]": [55,[11]],
		"/(web)/(chats)/chat/[name]": [~49,[11,14]],
		"/(web)/(chatbots)/compare/[slug]": [48,[11,13]],
		"/(web)/install": [56,[11]],
		"/(web)/(privacy-cookies)/privacy": [50,[11]],
		"/(web)/(alt-landings)/roleplay-ai": [46,[11,12]],
		"/(web)/(privacy-cookies)/terms": [51,[11]],
		"/(tools)/tools/fake-text-message-generator": [44],
		"/(web)/[slug]": [52,[11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';